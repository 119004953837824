var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"item-info grid grid-cols-2"},[_c('p',{staticClass:"typography-one"},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.popup.rentRequest.card.rider'))+" ")]),_c('p',{staticClass:"typography-two"},[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize cursor-pointer",attrs:{"to":{
            name: 'ViewRiderUserProfile',
            params: { id: _vm.data.user.id },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.data.user.full_name || '--')+" ")])],1)]),_c('div',{staticClass:"item-info grid grid-cols-2"},[_c('p',{staticClass:"typography-one"},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.popup.rentRequest.card.vehicleQR'))+" ")]),_c('p',{staticClass:"typography-two"},[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize cursor-pointer",attrs:{"to":{
            name: 'ViewRiderUserProfile',
            params: { id: _vm.data.bike.id },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.data.bike.qr_code || '--')+" ")])],1)]),_c('div',{staticClass:"item-info grid grid-cols-2"},[_c('p',{staticClass:"typography-one"},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.popup.rentRequest.card.pickupTime'))+" ")]),_c('p',{staticClass:"typography-two"},[_vm._v(" "+_vm._s(_vm.getFormattedDateTime(_vm.data.pick_up_time, 'h:mm:ss a,D MMM YY'))+" ")])]),_c('div',{staticClass:"item-info grid grid-cols-2"},[_c('p',{staticClass:"typography-one"},[_vm._v(" "+_vm._s(_vm.$t( 'components.tripManagement.popup.rentRequest.card.pickupLocation' ))+" ")]),_c('p',{staticClass:"typography-two"},[_vm._v(_vm._s(_vm.data.pick_up_location || '--'))])]),_c('div',{staticClass:"item-info grid grid-cols-2"},[_c('p',{staticClass:"typography-one"},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.popup.rentRequest.card.estimatedTime'))+" ")]),_c('p',{staticClass:"typography-two"},[_c('span',{staticClass:"text-oGreen"},[_vm._v(_vm._s(_vm.secToDHMSConverter(_vm.data.estimated_riding_time)))])])])]),_c('div',{staticClass:"footer"},[_c('section',{staticClass:"flex items-center py-4 px-3  space-x-5 "},[_c('anchor-button',{class:"w-1/2",attrs:{"variant":"warning-light","isLoading":_vm.data.is_rent_request_rejecting},on:{"click":function($event){return _vm.onUnlockRequestAction({
            action: 'reject',
          })}}},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.popup.rentRequest.card.reject'))+" ")]),_c('anchor-button',{class:"w-1/2",attrs:{"variant":"success","isLoading":_vm.data.is_rent_request_accepting},on:{"click":function($event){return _vm.onUnlockRequestAction({
            action: 'accept',
          })}}},[_vm._v(" "+_vm._s(_vm.$t('components.tripManagement.popup.rentRequest.card.accept'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }